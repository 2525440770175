import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@eyblockchain/ey-ui/core/TextField';

const PasswordField = ({ form, label, name, value, onChange, helperText }) => {
  const { dirty, touched, errors } = form;
  const hasError = dirty && touched[name] && errors[name] !== undefined;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const fieldProps = {
    name: name,
    label: label,
    value: value,
    component: TextField,
    variant: 'outlined',
    type: showPassword ? 'text' : 'password',
    required: true,
    helperText,
    autoComplete: 'off',
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            edge="end"
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? (
              <VisibilityOff color={hasError ? 'error' : 'inherit'} />
            ) : (
              <Visibility color={hasError ? 'error' : 'inherit'} />
            )}
          </IconButton>
        </InputAdornment>
      ),
    },
  };

  if (onChange !== null) fieldProps.onChange = onChange(name, form);

  return <Field {...fieldProps} />;
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  helperText: PropTypes.string,
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.object,
    touched: PropTypes.shape({}),
  }).isRequired,
};

PasswordField.defaultProps = {
  label: 'Password',
  onChange: null,
  helperText: '',
};

export default PasswordField;
