import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Main from '@eyblockchain/ey-ui/core/Main';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import Auth0EmbeddedLogin from '../idaasProviders/Auth0EmbeddedLogin';
import mixpanel from '../utils/mixpanel';

const authService = new Auth0EmbeddedLogin();

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  boldContent: {
    '& span': {
      fontWeight: 'bold',
    },
  },
  link: {
    padding: '0px',
    '&:hover': {
      background: 'none',
    },
  },
  resendMsg: {
    marginRight: theme.spacing(1),
  },
  toastButton: {
    minWidth: '48px',
  },
  buttonProgress: {
    marginLeft: theme.spacing(1),
  },
}));

const Confirmation = ({ notFound }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [pageKey, setPageKey] = useState('error-unknown');
  const [emailType, setEmailType] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [showResend, setShowResend] = useState(true);
  const [resendStatus, setResendStatus] = useState('OK');
  const [loading, setLoading] = React.useState(false);

  function reviewEmailType(origin) {
    if (origin === 'reset' || origin === 'forgot-password') {
      setEmailType('forgot');
    } else if (
      origin === 'verification' ||
      origin === 'registration' ||
      origin === 'resend-activate-link'
    ) {
      setEmailType('verification');
    }
  }

  useEffect(() => {
    if (notFound) {
      setPageKey('not-found');
    } else {
      let queryParams = Object.fromEntries(new URLSearchParams(location.search.replace('?', '')));

      if (queryParams.origin !== 'errorPage') {
        queryParams = Object.fromEntries(
          new URLSearchParams(atob(location.search.replace('?', ''))),
        );
      }

      setEmail(queryParams.email || '');
      reviewEmailType(queryParams.origin);
      if (queryParams.origin === 'redBoxCountry') {
        // track event on restricted location
        mixpanel.track('View Sign Up Restricted Page');
      }
      if (
        queryParams.origin === 'registration' ||
        queryParams.origin === 'forgot-password' ||
        queryParams.origin === 'redBoxCountry' ||
        queryParams.origin === 'registrationDisabled' ||
        queryParams.origin === 'resend-activate-link'
      ) {
        setPageKey(queryParams.origin);
      } else {
        // is an auth0 callback
        const errorDescription = decodeURIComponent(queryParams.message);
        let allowResend = false;

        if (errorDescription.toLocaleLowerCase() === 'access expired.') {
          setPageKey('error-expired');
          allowResend = true;
        } else if (errorDescription.toLocaleLowerCase() === 'this url can be used only once') {
          setPageKey('error-used');
        } else if (errorDescription.toLocaleLowerCase().includes('already verified')) {
          setPageKey('error-verifiedAccount');
        } else if (errorDescription.toLocaleLowerCase().includes('verification code is invalid')) {
          setPageKey('error-unknown');
        }

        setShowResend(allowResend);
      }
    }
  }, [location, notFound]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  async function resendForgotPassword() {
    try {
      await authService.forgotPass(email);
      setResendStatus('OK');
      return setOpenToast(true);
    } catch (err) {
      setResendStatus('ERROR');
      return err;
    }
  }

  async function resendWelcomeEmail() {
    setLoading(true);

    const body = {
      email: email,
    };

    try {
      const response = await fetch(`${window.platformConfig.authApiUrl}/login/account/verify`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setResendStatus('OK');
      } else {
        setResendStatus('ERROR');
      }

      setLoading(false);
      return setOpenToast(true);
    } catch (err) {
      setResendStatus('ERROR');
      setLoading(false);
      return setOpenToast(true);
    }
  }

  function clickResend() {
    if (emailType === 'forgot') {
      resendForgotPassword();
    } else if (emailType === 'verification') {
      resendWelcomeEmail();
    }
  }

  // The "pageKey" refers to the key in the translation page. In this way this page can be customized to show different confirmations.
  const pageTexts = {
    title: pageKey ? t(`confirmation.${pageKey}.title`) : '',
    mainMessage: pageKey ? `confirmation.${pageKey}.message` : '',
    resendMessage: pageKey ? t(`confirmation.${pageKey}.resendMessage`) : '',
    resendLink: pageKey ? t(`confirmation.${pageKey}.resendLink`) : '',
    resendAlert: pageKey ? t(`confirmation.${pageKey}.resendAlert`) : '',
  };

  return (
    <Main maxWidth="sm">
      <Typography variant="h3" className={classes.title}>
        {pageTexts.title}
      </Typography>

      <Typography component="p" className={classes.boldContent}>
        <Trans i18nKey={pageTexts.mainMessage} email={email}>
          <span>{{ email }}</span>
        </Trans>
      </Typography>

      <Typography component="div">
        <Box mt={4} mb={5}>
          {pageTexts.resendMessage !== '' && (
            <span className={classes.resendMsg}> {pageTexts.resendMessage} </span>
          )}
          {showResend && email !== '' ? (
            <>
              {loading ? (
                <CircularProgress size={24} className={classes.buttonProgress} />
              ) : (
                <Button
                  component={Link}
                  underline="always"
                  onClick={clickResend}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  className={classes.link}
                >
                  {pageTexts.resendLink}
                </Button>
              )}
            </>
          ) : (
            <Box fontWeight="bold">
              <Link underline="always" href="/">
                {pageTexts.resendLink}
              </Link>
            </Box>
          )}
        </Box>
      </Typography>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openToast}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <Box id="message-id" display="flex" flexDirection="row" alignItems="center">
            <Box pr={1}>
              {resendStatus === 'ERROR' ? (
                <ErrorIcon color="error" />
              ) : (
                <CheckCircleOutlineIcon style={{ color: '#168736' }} />
              )}
            </Box>
            {resendStatus === 'ERROR' ? (
              <Typography variant="body2">{t(`confirmation.resendError`)}</Typography>
            ) : (
              <Typography variant="body2">
                {pageTexts.resendAlert} <strong>{email}</strong>
              </Typography>
            )}
          </Box>
        }
        action={[
          <Button
            size="small"
            key="close"
            component={Link}
            underline="always"
            onClick={handleClose}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={clsx(classes.link, classes.toastButton)}
          >
            {t('confirmation.dismiss')}
          </Button>,
        ]}
      />
    </Main>
  );
};

Confirmation.propTypes = {
  notFound: PropTypes.bool,
};

Confirmation.defaultProps = {
  notFound: false,
};

export default Confirmation;
