import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Main from '@eyblockchain/ey-ui/core/Main';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChangePasswordForm from '../../components/ChangePasswordForm';
import { getUserData, getIdTokenInfo } from '../../utils/fetch';
import useNotification from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '8rem',
  },
  tooltip: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    minWidth: '54px',
  },
  subTitle: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  errorBox: {
    marginRight: '1px',
    marginLeft: '1px',
  },
}));

const Security = () => {
  const { showAlert } = useNotification();
  const { t } = useTranslation();
  const classes = useStyles();

  const [loadingPage, setLoadingPage] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const loadUser = async () => {
      try {
        const tokenInfo = getIdTokenInfo();
        const userId = tokenInfo.sub.replace('auth0|', '');
        const requestResponse = await getUserData(userId);
        const userInfo = requestResponse.data;
        setUserData(userInfo);
        setLoadingPage(false);
      } catch (err) {
        setLoadingPage(false);
        showAlert('error', t('profile.dataError'));
      }
    };
    loadUser();
  }, [t, showAlert]);

  return (
    <>
      <Main maxWidth="xl" className={classes.root}>
        <Box my={3}>
          <Typography variant="h2" className={classes.subTitle}>
            {t('security.title')}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4">{t('security.subtitle')}</Typography>
        </Box>

        <Box>
          <ChangePasswordForm loading={loadingPage} formData={userData} showAlert={showAlert} />
        </Box>
      </Main>
    </>
  );
};

export default Security;
