import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { fetchDefaultOrganization, insertDefaultOrganization } from './fetch';

/* eslint-disable no-underscore-dangle */
export const addCookieSecure = () => {
  return ['sandbox', 'production', 'stage'].includes(window.platformConfig.node_env);
};

const getUserId = () => {
  try {
    const idtoken = Cookies.get(`${window.platformConfig.cookiePrefix}id_token`);
    const tokenInfo = jwtDecode(idtoken);
    return tokenInfo.sub.replace('auth0|', '');
  } catch (error) {
    return null;
  }
};

const createDefaultOrganization = async () => {
  try {
    const insertRequest = await insertDefaultOrganization();

    if (insertRequest.status === 200) {
      let defaultConnection;
      let defaultAddress;
      const allConnections = insertRequest.data.connections;

      if (allConnections && allConnections.length > 0) {
        const ropstenConnection = allConnections.find(
          item => item.isSystemDefault && item.name.toLowerCase().startsWith('ropsten'),
        );

        if (ropstenConnection) {
          defaultConnection = `${ropstenConnection.networkId}|${ropstenConnection._id}`;
          defaultAddress = ropstenConnection.wallets[0].address;
        }
      }

      return {
        defaultOrgId: insertRequest.data._id,
        defaultOrgType: insertRequest.data.org_type,
        defaultConnection,
        defaultAddress,
      };
    }
  } catch (err) {
    throw new Error('Unable to create a default organization');
  }
  return null;
};

const findDefaultOrganizationData = async () => {
  try {
    // find the current user's default organization
    const userId = getUserId();
    const fetchResponse = await fetchDefaultOrganization(userId);

    if (fetchResponse.status === 200) {
      let defaultConnection;
      let defaultAddress;

      if (fetchResponse.data.connections) {
        const ropstenConnection = fetchResponse.data.connections.find(
          item => item.network === 'ropsten',
        );
        if (ropstenConnection && ropstenConnection.availableWallets) {
          const selectedWallet = ropstenConnection.availableWallets.find(
            item => item?.isActive === true,
          );

          defaultConnection = `${ropstenConnection.networkId}|${ropstenConnection.connectionId}`;

          defaultAddress = selectedWallet
            ? selectedWallet.address
            : ropstenConnection.availableWallets[0].address;
        }
      }

      return {
        defaultOrgId: fetchResponse.data._id,
        defaultOrgType: fetchResponse.data.org_type,
        defaultConnection,
        defaultAddress,
      };
    }
  } catch (err) {
    // if a default organization does not existed then created it (should happen only when the user log in for the first time)
    if (
      err.response.status === 404 &&
      err.response.data.message.toLowerCase().trim() === 'no default organization'
    ) {
      return createDefaultOrganization();
    }
    // eslint-disable-next-line no-console
    console.warn('Error on findDefaultOrganizationId');
  }
  return null;
};

/**
 * Set custom cookies
 */
// eslint-disable-next-line import/prefer-default-export
export const setCustomCookies = async () => {
  const {
    defaultOrgId,
    defaultOrgType,
    defaultConnection,
    defaultAddress,
  } = await findDefaultOrganizationData();

  if (!defaultOrgId) throw new Error('Unable to find a default organization');
  Cookies.set(`${window.platformConfig.cookiePrefix}active_organization`, btoa(defaultOrgId), {
    domain: window.platformConfig.cookieDomain,
    expires: 1,
    secure: addCookieSecure(),
  });
  if (defaultOrgType)
    Cookies.set(
      `${window.platformConfig.cookiePrefix}active_organization_type`,
      btoa(defaultOrgType),
      {
        domain: window.platformConfig.cookieDomain,
        expires: 1,
        secure: addCookieSecure(),
      },
    );
  if (defaultConnection && defaultAddress) {
    Cookies.set(`${window.platformConfig.cookiePrefix}active_wallet`, btoa(defaultAddress), {
      domain: window.platformConfig.cookieDomain,
      expires: 1,
      secure: addCookieSecure(),
    });
    Cookies.set(
      `${window.platformConfig.cookiePrefix}active_network_connection`,
      btoa(defaultConnection),
      {
        domain: window.platformConfig.cookieDomain,
        expires: 1,
        secure: addCookieSecure(),
      },
    );
  }
};

/**
 * Get custom organization cookies
 */
export const getOrganizationCookies = () => {
  const orgId = Cookies.get(`${window.platformConfig.cookiePrefix}active_organization`);
  const orgType = Cookies.get(`${window.platformConfig.cookiePrefix}active_organization_type`);

  if (orgId && orgType) return { defaultOrgId: atob(orgId), defaultOrgType: atob(orgType) };
  return {};
};
