import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import PasswordField from './PasswordField';
import MessageBox from './MessageBox';
import { checkCurrentPassword, changePassword } from '../utils/fetch';
import { pwRequirements, PASSWORD_REGEX } from '../utils/constants';
import mixpanel from '../utils/mixpanel';

const useStyles = makeStyles(() => ({
  tooltip: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    minWidth: '54px',
  },
  errorBox: {
    marginRight: '1px',
    marginLeft: '1px',
  },
}));

const ChangePasswordForm = ({ formData: userData, loading, showAlert }) => {
  let handleClickReset;

  const { t } = useTranslation();
  const classes = useStyles();

  const [loadingChanges, setLoadingChanges] = useState(false);
  const [changeRequestStatus, setChangeRequestStatus] = useState('');

  const generalError = () => (
    <>
      {t('security.actionMessages.generalError')}{' '}
      <a href="/support"> {t('security.actionMessages.supportLink')}</a>.
    </>
  );

  function checkFailedRequest(err) {
    if (err.status === 403 && err.data.message.toLowerCase() === 'wrong email or password.') {
      setChangeRequestStatus(t('security.actionMessages.wrongCurrentPassword'));
    } else if (err.status === 429 && err.statusText.toLowerCase() === 'too many requests') {
      setChangeRequestStatus(t('security.actionMessages.tooManyRequests'));
    } else {
      setChangeRequestStatus('general error');
    }
  }

  async function submitChangePasswordForm(values) {
    setLoadingChanges(true);
    try {
      const userId = userData.id.replace('auth0|', '');
      const verifyPassword = await checkCurrentPassword(userData.email, values.currentPassword);

      if (verifyPassword.status === 200) {
        await changePassword(userId, values.confirmedPassword, values.currentPassword);
        setChangeRequestStatus('');
        showAlert('success', t('security.actionMessages.changePasswordSuccess'));
        mixpanel.track('Password Update');
      }
    } catch (err) {
      mixpanel.track('Failed Password Update');
      checkFailedRequest(err.response || err);
    }
    setLoadingChanges(false);
  }

  function bindFormikProps(formikProps) {
    handleClickReset = formikProps.submitForm;
  }

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string(),
    newPassword: Yup.string()
      .required(t('security.actionMessages.passwordRequirements'))
      .min(10, t('security.actionMessages.passwordRequirements'))
      .matches(PASSWORD_REGEX, t('security.actionMessages.passwordRequirements'))
      .notOneOf([Yup.ref('currentPassword'), null], t('security.validations.newPassword')),
    confirmedPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      t('security.validations.confirmPassword'),
    ),
  });

  return (
    <div>
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmedPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={submitChangePasswordForm}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => {
          bindFormikProps(formikProps);

          return (
            <Form noValidate autoComplete="off">
              <MaskedLoader loading={loadingChanges || loading} />

              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormErrors form={formikProps} className={classes.errorBox} />
                </Grid>
              </Grid>

              {changeRequestStatus !== '' && Object.keys(formikProps.errors).length === 0 && (
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <MessageBox type="error" className={classes.errorBox}>
                      <Typography component="span" variant="body2">
                        {changeRequestStatus === 'general error'
                          ? generalError()
                          : changeRequestStatus}
                      </Typography>
                    </MessageBox>
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item xs={10} sm={8} md={5} lg={4}>
                  <PasswordField
                    label={t('security.labels.currentPassword')}
                    name="currentPassword"
                    value={formikProps.values.currentPassword}
                    form={formikProps}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
                <Grid item xs={10} sm={8} md={5} lg={4}>
                  <PasswordField
                    label={t('security.labels.newPassword')}
                    name="newPassword"
                    value={formikProps.values.newPassword}
                    form={formikProps}
                  />
                </Grid>

                <Tooltip title={pwRequirements} className={classes.tooltip} placement="right">
                  <Button>
                    <InfoIcon />
                  </Button>
                </Tooltip>
              </Grid>

              <Grid item xs={10} sm={8} md={5} lg={4}>
                <PasswordField
                  label={t('security.labels.confirmPassword')}
                  name="confirmedPassword"
                  value={formikProps.values.confirmedPassword}
                  form={formikProps}
                />
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Box mt={1.5}>
        <Button variant="contained" color="primary" onClick={() => handleClickReset()}>
          {t('security.resetPasswordButton')}
        </Button>
      </Box>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  /**
   * Boolean value to indicate if the parent finishes loading the user data.
   */
  loading: PropTypes.bool.isRequired,

  /**
   * Form data .
   */
  formData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,

  /**
   * Function to show toast alerts
   */
  showAlert: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
