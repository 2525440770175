import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import * as Yup from 'yup';
import Auth0EmbeddedLogin from '../idaasProviders/Auth0EmbeddedLogin';

const authService = new Auth0EmbeddedLogin();

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  disableButton: {
    color: '#c4c4cd',
    backgroundColor: 'rgba(196,196,205,0.35) !important',
    border: '1px solid rgba(196, 196, 205, 0.35) !important',
  },
}));

function ForgotPasswordForm({ history, className: classNameProp, ...other }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const className = clsx(classes.root, classNameProp);

  const [submitDisable, setSubmitDisable] = useState(true);
  const [sending, setSending] = useState(false);

  async function forgotPassword(email) {
    try {
      await authService.forgotPass(email);
      const params = btoa(`origin=forgot-password&email=${email}`);
      return history.push(`/confirmation?${params}`);
    } catch (err) {
      return err;
    }
  }

  function handleSubmit(values) {
    setSubmitDisable(true);
    setSending(true);
    forgotPassword(values.email);
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(),
  });

  const validateEmail = async value => {
    setSubmitDisable(true);
    try {
      await validationSchema.validate({ email: value });
      setSubmitDisable(false);
    } catch (err) {
      setSubmitDisable(true);
      throw err.message;
    }
  };

  return (
    <div className={className} {...other}>
      <Formik
        initialValues={{
          email: '',
        }}
        validateOnBlur={false}
        validateOnChange
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ dirty, errors, touched, values }) => (
          <Form noValidate autoComplete="off">
            <Field
              name="email"
              label={t('forgot-password.labels.email')}
              value={values.email}
              component={TextField}
              variant="outlined"
              validate={validateEmail}
              helperText={
                dirty && touched.email && errors.email ? t('forgot-password.errors.email') : ''
              }
              required
            />
            <Box mt={5}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={!dirty || submitDisable ? classes.disableButton : ''}
                disabled={!dirty || submitDisable}
              >
                {sending
                  ? t('forgot-password.buttons.sending')
                  : t('forgot-password.buttons.submit')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
}

ForgotPasswordForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ForgotPasswordForm.defaultProps = {
  className: null,
};

export default ForgotPasswordForm;
