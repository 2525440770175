import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import * as Yup from 'yup';
import {
  PASSWORD_REGEX,
  pwRequirements,
  privacyUrl,
  termsAndConditionsUrl,
  mixpanelEvents,
} from '../utils/constants';
import PasswordField from './PasswordField';
import MessageBox from './MessageBox';
import mixpanel from '../utils/mixpanel';
import Auth0EmbeddedLogin from '../idaasProviders/Auth0EmbeddedLogin';

const authService = new Auth0EmbeddedLogin();

const useStyles = makeStyles(theme => ({
  formSpacing: {
    marginBottom: theme.spacing(8),
  },
  chxLabel: {
    cursor: 'pointer',
  },
  linkSpacing: {
    padding: theme.spacing(0, 1),
  },
  disableButton: {
    '&:disabled': {
      backgroundColor: 'rgba(196, 196, 205, 0.35)',
      color: 'rgba(46, 46, 56, 0.69)',
    },
  },
  link: {
    color: '#155CB4',
    fontWeight: 'bold',
  },
}));

function RegistrationForm({ className: classNameProp, history, ...other }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const className = clsx(classes.root, classNameProp);

  const [acceptedTermsOne, setAcceptedTermsOne] = useState(false);
  const [acceptedTermsTwo, setAcceptedTermsTwo] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [signUpFail, setSignUpFail] = useState(false);
  const [failReason, setFailReason] = useState('registration.errors.signUpError');

  const handleCheck = check => {
    if (check === 'firstCheck') {
      setAcceptedTermsOne(!acceptedTermsOne);
    } else {
      setAcceptedTermsTwo(!acceptedTermsTwo);
    }
  };

  const verifyEmail = async email => {
    try {
      const response = await fetch(`${window.platformConfig.authApiUrl}/users/status/${email}`, {
        method: 'GET',
      });
      return response.json();
    } catch (err) {
      return err;
    }
  };

  const sendVerificationLink = async email => {
    try {
      const body = {
        email,
        isUnverified: true,
      };

      const response = await fetch(`${window.platformConfig.authApiUrl}/login/account/verify`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.json();
    } catch (err) {
      return err;
    }
  };

  async function signup(values) {
    try {
      setWaiting(true);
      const accountVerification = await verifyEmail(values.email_address);
      let origin = 'registration';

      if (!accountVerification.status && accountVerification.message === undefined) {
        await sendVerificationLink(values.email_address);
        origin = 'resend-activate-link';
      } else {
        await authService.signUp(values);
        mixpanel.track('Sign Up');
      }

      const params = btoa(`origin=${origin}&email=${values.email_address.toLowerCase()}`);
      return history.push(`/confirmation?${params}`);
    } catch (err) {
      if (err.statusCode === 400 && err.code.toLowerCase() === 'user_exists') {
        setFailReason('registration.errors.accountExists');
      }
      setSignUpFail(true);
      setWaiting(false);
      return err;
    }
  }

  function handleSubmit(values) {
    signup(values);
  }

  function onKeyDown(keyEvent) {
    if (
      (keyEvent.charCode || keyEvent.keyCode) === 13 &&
      (!acceptedTermsOne || !acceptedTermsTwo)
    ) {
      keyEvent.preventDefault();
    }
  }

  const handleChange = (field, { setFieldValue, setErrors, errors }) => e => {
    setFieldValue(field, e.target.value);
    if (Object.values(errors).length > 0) setErrors({});
    if (signUpFail) setSignUpFail(false);
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(t('registration.errors.required.fullname')),
    email_address: Yup.string()
      .email(t('registration.errors.format.email'))
      .required(t('registration.errors.required.email')),
    password: Yup.string()
      .required(t('registration.errors.required.password'))
      .min(10, t('registration.passwordRequirements'))
      .matches(PASSWORD_REGEX, t('registration.passwordRequirements')),
  });

  // requires the query parameters to be encrypted using btoa function
  const params = Object.fromEntries(
    new URLSearchParams(atob(history.location.search.replace('?', ''))),
  );

  function trackViewLegalDocumentation(label) {
    mixpanel.track(mixpanelEvents.viewLegalDocumentation, { documentName: label });
  }

  return (
    <div className={className} {...other}>
      <Formik
        initialValues={{
          full_name: params.name || '',
          email_address: params.email ? params.email.toLowerCase() : '',
          password: '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formProps => (
          <Form noValidate autoComplete="off">
            <FormErrors form={formProps} />

            {signUpFail && Object.keys(formProps.errors).length === 0 && (
              <MessageBox type="error">
                <Typography component="span" variant="body2">
                  {t(failReason)}
                </Typography>
              </MessageBox>
            )}

            <div className={classes.formSpacing}>
              <Field
                name="full_name"
                label={t('registration.labels.fullname')}
                value={formProps.values.full_name}
                component={TextField}
                variant="outlined"
                autoComplete="new-name"
                onChange={handleChange('full_name', formProps)}
                onKeyDown={onKeyDown}
                required
              />

              <Field
                name="email_address"
                label={t('registration.labels.email')}
                value={formProps.values.email_address}
                component={TextField}
                variant="outlined"
                autoComplete="off"
                onChange={handleChange('email_address', formProps)}
                visibility="hidden"
                onKeyDown={onKeyDown}
                required
              />

              <PasswordField
                name="password"
                value={formProps.values.password}
                form={formProps}
                helperText={pwRequirements}
                autoComplete="new-password"
                onChange={handleChange}
                visibility="hidden"
                onKeyDown={onKeyDown}
              />

              <Box display="flex" flexDirection="row" pt={2}>
                <Box>
                  <Checkbox
                    id="firstCheckbox"
                    color="primary"
                    onChange={() => handleCheck('firstCheck')}
                  />
                </Box>
                <Box pt={1}>
                  <Typography
                    className={classes.chxLabel}
                    variant="body2"
                    component="label"
                    htmlFor="firstCheckbox"
                  >
                    {t('registration.disclaimerOne')}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" pt={1}>
                <Box>
                  <Checkbox
                    id="secondCheckbox"
                    color="primary"
                    onChange={() => handleCheck('secondCheck')}
                  />
                </Box>
                <Box pt={1}>
                  <Typography
                    className={classes.chxLabel}
                    variant="body2"
                    component="label"
                    htmlFor="secondCheckbox"
                  >
                    {t('registration.disclaimerTwo')}
                    <Link
                      href={termsAndConditionsUrl}
                      target="_blank"
                      className={classes.link}
                      underline="always"
                      onClick={() => {
                        trackViewLegalDocumentation('terms');
                      }}
                    >
                      &nbsp;{t('registration.termsLink')}
                    </Link>
                    <span>&nbsp;and&nbsp;</span>
                    <Link
                      href={privacyUrl}
                      target="_blank"
                      className={classes.link}
                      underline="always"
                      onClick={() => {
                        trackViewLegalDocumentation('privacy');
                      }}
                    >
                      {t('registration.privacyLink')}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.disableButton}
              disabled={!(acceptedTermsOne && acceptedTermsTwo) || waiting}
            >
              {waiting ? t('registration.buttons.submitWaiting') : t('registration.buttons.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

RegistrationForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.object,
  }).isRequired,
};

RegistrationForm.defaultProps = {
  className: null,
};

export default RegistrationForm;
