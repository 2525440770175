import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const NotificationContext = React.createContext({
  data: null,
  showAlert: () => {},
  removeAlert: () => {},
});

export default function NotificationProvider({ children }) {
  const [data, setData] = useState(null);

  const contextValue = {
    data,
    showAlert: useCallback(
      (variant, message, customActions) => setData({ variant, message, customActions }),
      [],
    ),
    removeAlert: useCallback(() => setData(null), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = { children: PropTypes.node.isRequired };
