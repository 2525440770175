import Cookies from 'js-cookie';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

const getRequestOptions = () => {
  const accessToken = Cookies.get(`${window.platformConfig.cookiePrefix}access_token`);
  const idToken = Cookies.get(`${window.platformConfig.cookiePrefix}id_token`);
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      IDTOKEN: idToken,
    },
  };
};

export const getUserData = id => {
  const url = `${window.platformConfig.authApiUrl}/users/${id}`;
  const result = axios.get(url, getRequestOptions());
  return result;
};

export const updateUserInfo = async (id, body) => {
  const result = await axios.put(
    `${window.platformConfig.authApiUrl}/users/${id}`,
    body,
    getRequestOptions(),
  );

  return result;
};

export const checkCurrentPassword = (email, pw) => {
  const result = axios.post(
    `${window.platformConfig.authApiUrl}/login`,
    {
      username: email,
      password: pw,
    },
    getRequestOptions(),
  );

  return result;
};

export const changePassword = (id, pw, currentPassword) => {
  const result = axios.put(
    `${window.platformConfig.authApiUrl}/users/password/${id}`,
    {
      password: pw,
      currentPassword,
    },
    getRequestOptions(),
  );
  return result;
};

export const getIdTokenInfo = () => {
  try {
    const idtoken = Cookies.get(`${window.platformConfig.cookiePrefix}id_token`);
    const tokenInfo = jwtDecode(idtoken);

    return { ...tokenInfo };
  } catch (err) {
    return null;
  }
};

export const deleteUserProfile = id => {
  const url = `${window.platformConfig.authApiUrl}/users/${id}`;
  const result = axios.delete(url, getRequestOptions());
  return result;
};

export const createUserKey = data => {
  const result = axios.post(
    `${window.platformConfig.authApiUrl}/wallet`,
    data,
    getRequestOptions(),
  );
  return result;
};

export const deleteUserKey = address => {
  const url = `${window.platformConfig.authApiUrl}/wallet/${address}`;
  const result = axios.delete(url, getRequestOptions());
  return result;
};

export const getUserKeys = () => {
  const url = `${window.platformConfig.authApiUrl}/wallet`;
  const result = axios.get(url, getRequestOptions());
  return result;
};

export const getUserOrganizations = id => {
  const url = `${window.platformConfig.becUrl}/api/users/${id}/organizations`;
  const result = axios.get(url, getRequestOptions());

  return result;
};

/**
 * Get current users for an organization
 * @param {string} id - Organization DB identifier
 */
export const getCurrentOrgUsers = id => {
  const url = `${window.platformConfig.becUrl}/api/organizations/${id}/users`;
  const result = axios.get(url, getRequestOptions());
  return result;
};

export const fetchDefaultOrganization = userId => {
  const url = `${window.platformConfig.becUrl}/api/users/${userId}/my-organization`;
  return axios.get(url, getRequestOptions());
};

export const insertDefaultOrganization = () => {
  const body = {
    name: getIdTokenInfo().name,
    address: {
      street_line: '',
      country: '',
      city: '',
      postal_code: '',
      state: '',
      phone: '',
    },
    org_type: 'user',
  };

  const url = `${window.platformConfig.becUrl}/api/organizations`;
  return axios.post(url, body, getRequestOptions());
};
