import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: ({ accountPage }) => {
    return {
      top: 'auto',
      bottom: 0,
      padding: 0,
      height: 'auto',
      backgroundColor: theme.palette.primary.contrastText,
      left: accountPage ? theme.typography.pxToRem(250) : theme.spacing(0),
    };
  },
  toolbar: {
    padding: theme.spacing(2),
  },
  customButtom: {
    padding: `${theme.spacing(1)}px !important`,
  },
}));

const ActionBar = ({ className: classNameProp, enableSave, onClickSave, onClickCancel }) => {
  const accountPage = window.location.pathname.startsWith('/account');

  const classes = useStyles({ accountPage });
  const className = clsx(classes.root, classNameProp);
  const { t } = useTranslation();

  return (
    <AppBar position="fixed" className={className}>
      <Toolbar className={classes.toolbar}>
        <Box mr={2}>
          <Button
            type="button"
            variant="contained"
            className={classes.customButtom}
            onClick={onClickCancel}
          >
            {t('profile.actionBar.cancelButton')}
          </Button>
        </Box>
        <Button
          disabled={!enableSave}
          type="button"
          variant="contained"
          color="secondary"
          className={classes.customButtom}
          onClick={onClickSave}
        >
          {t('profile.actionBar.saveButton')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

ActionBar.propTypes = {
  /**
   * Additional classes to be applied to root element.
   */
  className: PropTypes.string,
  /**
   * Function for the save button.
   */
  onClickSave: PropTypes.func.isRequired,
  /**
   * Function for the cancel button.
   */
  onClickCancel: PropTypes.func.isRequired,
  /**
   * Boolean to trigger save button.
   */
  enableSave: PropTypes.bool.isRequired,
};

ActionBar.defaultProps = {
  className: null,
};

export default ActionBar;
