import React from 'react';
import { Helmet } from 'react-helmet';

export default () => (
  <>
    <Helmet>
      {['development', 'stage'].includes(window.platformConfig.node_env) && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
  </>
);
