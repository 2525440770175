import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Main from '@eyblockchain/ey-ui/core/Main';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { privacyUrl, termsAndConditionsUrl } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

function Legal() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Main maxWidth={false} className={classes.root}>
        <Typography className={classes.title} variant="h2">
          {t('legal.title')}
        </Typography>
        <Typography className={classes.sectionTitle} variant="h4">
          {t('legal.privacyPolicy')}
        </Typography>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          endIcon={<OpenInNewIcon />}
          href={privacyUrl}
          target="_blank"
        >
          {t('legal.reviewPolicy')}
        </Button>
        <Typography className={classes.sectionTitle} variant="h4">
          {t('legal.termsTitle')}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<OpenInNewIcon />}
          href={termsAndConditionsUrl}
          target="_blank"
        >
          {t('legal.reviewTerms')}
        </Button>
      </Main>
    </>
  );
}
export default Legal;
