import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Modal from '@eyblockchain/ey-ui/core/Modal';
import { Form, Field, Formik } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import Select from '@eyblockchain/ey-ui/core/Select';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { WalletContext } from '@eyblockchain/ey-ui/core/BecWalletMenu';
import { reviewSubscriptions } from '@eyblockchain/subscription-sdk/browser';
import privateToAddressEth from '../utils/crypto';

const useStyles = makeStyles(theme => ({
  tab: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginBottom: 10,
  },
}));

function TabPanel({ children, createKeyMode, index, ...other }) {
  return (
    <Box
      role="tabpanel"
      hidden={createKeyMode !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {createKeyMode === index && <Typography component="span">{children}</Typography>}
    </Box>
  );
}

function AddKeyModal({ open, onClose, showAlert }) {
  let handleClickReset;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [createKeyMode, setCreateKeyMode] = useState(0);
  const { loadWallet, createKey } = useContext(WalletContext);
  const [blockchainTypeArray, setblockchainTypeArray] = useState([]);
  // Form related setup
  const initialValues = {
    name: '',
    blockchainTypeLabel: '',
    blockchainTypeValue: '',
    encryptionTypeLabel: t('wallet.key.encryptionEcdsa'),
    encryptionTypeValue: 0,
    encryptionTypeValueBSN: 1,
    nameImport: '',
    keyImport: '',
  };

  useEffect(() => {
    async function checkSubscriptions() {
      const verifiedSubscriptions = await reviewSubscriptions(['bsn_opschain_temp']);
      if (verifiedSubscriptions.includes('bsn_opschain_temp')) {
        setblockchainTypeArray([
          { label: t('wallet.key.nameFisco'), value: 'BCOS' },
          { label: t('wallet.key.nameEth'), value: 'ETH' },
          { label: t('wallet.key.nameBSN'), value: 'BSN' },
        ]);
      } else {
        setblockchainTypeArray([
          { label: t('wallet.key.nameFisco'), value: 'BCOS' },
          { label: t('wallet.key.nameEth'), value: 'ETH' },
        ]);
      }
    }
    checkSubscriptions();
  }, [t]);
  // Option can be hardcoded as they are generally fixed unless new developer setup

  // Options can be hardcoded as they are static
  const encryptionTypeArray = [{ label: t('wallet.key.encryptionEcdsa'), value: 0 }];
  const bsnEncryptionTypeArray = [{ label: t('wallet.key.encryptionBSN'), value: 1 }];

  const bindFormikProps = formikProps => {
    handleClickReset = formikProps.submitForm;
  };

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const appBar = (
    <AppBar position="static" className={classes.tab}>
      <Tabs
        value={createKeyMode}
        indicatorColor="secondary"
        onChange={(event, newMode) => {
          setCreateKeyMode(newMode);
        }}
      >
        <Tab label={t('wallet.action.createKey')} {...a11yProps(0)} />
      </Tabs>
    </AppBar>
  );

  const createKeyHandler = async (blockchainType, encryptionType, keyName) => {
    let response;
    try {
      response = await createKey({
        blockchainType,
        encryptionType,
        keyName,
      });
    } catch (err) {
      showAlert('error', t('wallet.addModal.error'));
    }
    if (response?.status === 201) {
      showAlert('success', t('wallet.addModal.success'));
      await loadWallet();
      onClose();
    }
  };

  const onSubmit = async (values, actions) => {
    const { blockchainTypeValue, encryptionTypeValue, encryptionTypeValueBSN, name } = values;
    if (createKeyMode === 0 && blockchainTypeValue === 'BSN') {
      await createKeyHandler(blockchainTypeValue, encryptionTypeValueBSN, name);
    }
    if (createKeyMode === 0 && blockchainTypeValue === 'BCOS') {
      await createKeyHandler(blockchainTypeValue, encryptionTypeValue, name);
    }
    if (createKeyMode === 0 && blockchainTypeValue === 'ETH') {
      await createKeyHandler(blockchainTypeValue, encryptionTypeValue, name);
    }
    actions.resetForm(initialValues);
  };

  const formContainer = (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        keyImport:
          createKeyMode === 1 &&
          Yup.string()
            .required(t('wallet.addModal.keyRequired'))
            .matches(new RegExp('^(0x)?[0-9A-Fa-f]{64}$'), t('wallet.addModal.keyInvalid')),
        nameImport: createKeyMode === 1 && Yup.string().required(t('wallet.addModal.nameRequired')),
        name: createKeyMode === 0 && Yup.string().required(t('wallet.addModal.nameRequired')),
        blockchainTypeValue:
          createKeyMode === 0 && Yup.string().required(t('wallet.addModal.typeRequired')),
      })}
    >
      {formikProps => {
        bindFormikProps(formikProps);

        return (
          <Form>
            <TabPanel createKeyMode={createKeyMode} index={0}>
              <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
                <Field
                  name="name"
                  component={TextField}
                  label={t('wallet.key.name')}
                  value={formikProps.values.name}
                />
              </Grid>
              {formikProps.errors.name && formikProps.touched.name ? (
                <div>{formikProps.errors.name}</div>
              ) : null}

              <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
                <Field
                  name="blockchainTypeLabel"
                  component={Select}
                  label={t('wallet.key.type')}
                  value={formikProps.values.blockchainTypeValue}
                  options={blockchainTypeArray}
                  onChange={(e, v) => {
                    formikProps.setFieldValue('blockchainTypeValue', v.props.value);
                    formikProps.setFieldValue('blockchainTypeLabel', v.props.children);
                  }}
                />
              </Grid>
              {formikProps.errors.blockchainTypeValue && formikProps.touched.blockchainTypeValue ? (
                <div>{formikProps.errors.blockchainTypeValue}</div>
              ) : null}

              <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
                {formikProps.values.blockchainTypeValue === 'BCOS' && (
                  <Field
                    name="encryptionTypeLabel"
                    component={Select}
                    label={t('wallet.key.encryption')}
                    onChange={(e, v) => {
                      const { ...encryptionType } = v;

                      formikProps.setFieldValue('encryptionTypeValue', encryptionType.props.value);
                      formikProps.setFieldValue(
                        'encryptionTypeLabel',
                        encryptionType.props.children,
                      );
                    }}
                    value={formikProps.values.encryptionTypeValue}
                    options={encryptionTypeArray}
                  />
                )}
                {formikProps.values.blockchainTypeValue === 'BSN' && (
                  <Field
                    name="encryptionTypeLabel"
                    component={Select}
                    label={t('wallet.key.encryption')}
                    onChange={(e, v) => {
                      const { ...encryptionType } = v;

                      formikProps.setFieldValue('encryptionTypeValue', encryptionType.props.value);
                      formikProps.setFieldValue(
                        'encryptionTypeLabel',
                        encryptionType.props.children,
                      );
                    }}
                    value={formikProps.values.encryptionTypeValueBSN}
                    options={bsnEncryptionTypeArray}
                  />
                )}
              </Grid>
            </TabPanel>

            <TabPanel createKeyMode={createKeyMode} index={1}>
              <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
                <Field
                  name="nameImport"
                  component={TextField}
                  label={t('wallet.key.name')}
                  value={formikProps.values.nameImport}
                />
              </Grid>
              {formikProps.errors.nameImport && formikProps.touched.nameImport ? (
                <div>{formikProps.errors.nameImport}</div>
              ) : null}

              <Grid container direction="row" wrap="wrap" alignContent="flex-start">
                <Field
                  name="keyImport"
                  component={TextField}
                  label={t('wallet.key.signingKey')}
                  value={formikProps.values.keyImport}
                  multiline
                />
              </Grid>
              {formikProps.errors.keyImport && formikProps.touched.keyImport ? (
                <div>{formikProps.errors.keyImport}</div>
              ) : null}
              {!formikProps.errors.keyImport ? (
                <div>
                  {t('wallet.key.address')}: {privateToAddressEth(formikProps.values.keyImport)}
                </div>
              ) : null}
            </TabPanel>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <Modal
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      scroll="body"
      title={t('wallet.addModal.title')}
      showCloseIcon
      open={open}
      onClose={onClose}
      splitActions={false}
      rightActionOptions={{
        name: t('wallet.action.add'),
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          handleClickReset();
        },
      }}
      leftActionOptions={{
        name: t('wallet.action.cancel'),
        color: 'primary',
        variant: 'outlined',
        startIcon: null,
        onClick: onClose,
      }}
    >
      {appBar}
      {formContainer}
    </Modal>
  );
}

AddKeyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  showAlert: PropTypes.func.isRequired,
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  createKeyMode: PropTypes.number.isRequired,
};

export default AddKeyModal;
