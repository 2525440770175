import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { getIdTokenInfo, getUserData, updateUserInfo } from '../utils/fetch';

export const UserContext = React.createContext({
  getUserInfo: () => {},
  getPreferenceLanguage: () => {},
  updatePreferenceLanguage: () => {},
  userInfoData: null,
});

const UserInfoProvider = ({ children }) => {
  const [userInfoData, setUserInfoData] = useState(null);
  const getUserInfo = async () => {
    const tokenInfo = getIdTokenInfo();
    const userId = tokenInfo.sub.replace('auth0|', '');
    const { data: userInfo } = await getUserData(userId);
    setUserInfoData(userInfo);
    return userInfo;
  };
  const getPreferenceLanguage = async () => {
    const userInfo = await getUserInfo();
    if (
      userInfo?.user_metadata?.language &&
      i18next.language !== userInfo?.user_metadata?.language
    ) {
      await i18next.changeLanguage(userInfo.user_metadata.language);
    }
  };

  const updatePreferenceLanguage = async value => {
    const tokenInfo = getIdTokenInfo();
    const userId = tokenInfo.sub.replace('auth0|', '');
    await updateUserInfo(userId, { user_metadata: { language: value } });
    await i18next.changeLanguage(value);
  };

  return (
    <UserContext.Provider
      value={{
        userInfoData,
        getPreferenceLanguage,
        updatePreferenceLanguage,
        getUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserInfoProvider;
