import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@eyblockchain/ey-ui/core/Modal';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import { deleteUserProfile } from '../utils/fetch';
import IdaasProviders from '../idaasProviders';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: '64px',
    width: '64px',
    borderRadius: '50%',
  },
  profileInfo: {
    marginLeft: theme.spacing(2),
  },
  chxLabel: {
    cursor: 'pointer',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
}));

const authService = new IdaasProviders(window.platformConfig.becUrl);

function DeleteAccountModal({ user, open, onClose, showAlert }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [checkboxConfirm, setCheckboxConfirm] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const { t } = useTranslation();

  // eslint-disable-next-line consistent-return
  async function clickDeleteButton() {
    const id = user.id.replace('auth0|', '');
    setSendingRequest(true);

    try {
      const response = await deleteUserProfile(id);

      if (response.status === 204) {
        setCheckboxConfirm(false);
        setSendingRequest(false);
        authService.signOut(window.config.headerLogoUrl);
      }
    } catch (err) {
      setSendingRequest(false);
      setCheckboxConfirm(false);
      showAlert('error', t('settings.deleteAccount.requestError'));
    }
  }

  function handleCheck() {
    setCheckboxConfirm(!checkboxConfirm);
  }

  function onModalClose() {
    setCheckboxConfirm(false);
    onClose();
  }

  return (
    <Modal
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      scroll="body"
      title={t('settings.deleteAccount.title')}
      showCloseIcon
      open={open}
      onClose={() => onModalClose()}
      splitActions={false}
      rightActionOptions={{
        name: t('settings.deleteAccount.title'),
        color: 'primary',
        variant: 'contained',
        startIcon: null,
        disabled: !checkboxConfirm,
        className: classes.deleteButton,
        onClick: () => {
          clickDeleteButton();
        },
      }}
      leftActionOptions={{
        name: t('settings.deleteAccount.cancelButton'),
        color: 'primary',
        variant: 'outlined',
        startIcon: null,
        onClick: () => {
          onModalClose();
        },
      }}
    >
      <MaskedLoader loading={sendingRequest} />

      <Typography className={classes.sectionText}>
        {t('settings.deleteAccount.instructions')}
      </Typography>

      <Box mt={3}>
        <Grid container>
          <img className={classes.avatar} src={user.picture} alt="avatar" />
          <Grid item xs={6} className={classes.profileInfo}>
            <Typography variant="h6">{user.name}</Typography>
            <Typography>{user.email}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="row" mt={2}>
        <Checkbox id="confirmation" color="primary" onChange={() => handleCheck()} />
        <Box alignSelf="center">
          <Typography
            className={classes.chxLabel}
            variant="body2"
            component="label"
            htmlFor="confirmation"
          >
            <Trans i18nKey="settings.deleteAccount.checkBoxText">
              <strong>[bold]</strong>
            </Trans>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

DeleteAccountModal.propTypes = {
  /**
   * Function to close modal .
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Function to toggle modal visibility .
   */
  open: PropTypes.bool.isRequired,
  /**
   * User data
   */
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,

  /**
   * Function to show toast alerts
   */
  showAlert: PropTypes.func.isRequired,
};

export default DeleteAccountModal;
