import React from 'react';
import Snackbar from '@eyblockchain/ey-ui/core/Snackbar';
import useNotification from '../hooks/useNotification';

function Notification() {
  const { data, removeAlert } = useNotification();
  return (
    <Snackbar
      open={!!data}
      onClose={removeAlert}
      message={data?.message || ''}
      variant={data?.variant}
      customActions={data?.customActions}
    />
  );
}

export default Notification;
