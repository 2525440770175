import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Main from '@eyblockchain/ey-ui/core/Main';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import ProfileForm from '../../components/ProfileForm';
import { getUserData, getIdTokenInfo } from '../../utils/fetch';
import ActionBar from '../../components/ActionBar';
import mixpanel from '../../utils/mixpanel';
import useNotification from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '8rem',
  },
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
}));

function Profile() {
  const { showAlert } = useNotification();
  let handleSubmitForm;
  let handleResetForm;

  const classes = useStyles();
  const { t } = useTranslation();

  const [loadingPage, setLoadingPage] = useState(true);
  const [userData, setUserData] = useState({});
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const tokenInfo = getIdTokenInfo();
        const userId = tokenInfo.sub.replace('auth0|', '');

        const requestResponse = await getUserData(userId);
        const userInfo = requestResponse.data;
        userInfo.picture = getIdTokenInfo().picture;
        setUserData(userInfo);
        setLoadingPage(false);
      } catch (err) {
        setLoadingPage(false);
        showAlert('error', t('profile.dataError'));
      }
    };
    loadUser();
  }, [t, showAlert]);

  function onClickSave() {
    if (handleSubmitForm) {
      handleSubmitForm();
    }
  }

  function onClickCancel() {
    if (handleResetForm) {
      handleResetForm(userData);
    }
  }

  function bindFormikProps(formikProps) {
    handleSubmitForm = formikProps.submitForm;
    handleResetForm = formikProps.resetForm;

    const enableSave =
      Object.keys(formikProps.touched).length > 0 &&
      Object.keys(formikProps.errors).length === 0 &&
      formikProps.dirty;
    setEnableSaveButton(enableSave);
  }

  function updateUserData(values) {
    mixpanel.track('Profile Data Update');
    setUserData(prevState => ({
      ...prevState,
      name: values.name,
      email: values.email,
    }));
  }

  return (
    <>
      <Main maxWidth={false} className={classes.root}>
        <Typography className={classes.title} variant="h2">
          {t('profile.title')}
        </Typography>

        <ProfileForm
          loading={loadingPage}
          formData={userData}
          bindFormikProps={bindFormikProps}
          showAlert={showAlert}
          onDataUpdated={updateUserData}
        />
      </Main>
      <ActionBar
        enableSave={enableSaveButton}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
      />
    </>
  );
}

export default Profile;
