import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFeature } from '@eyblockchain/subscription-sdk/browser';
import Main from '@eyblockchain/ey-ui/core/Main';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteAccountModal from '../../components/DeleteAccountModal';
import LanguageForm from '../../components/LanguageForm';
import {
  getUserData,
  getIdTokenInfo,
  getUserOrganizations,
  getCurrentOrgUsers,
} from '../../utils/fetch';
import mixpanel from '../../utils/mixpanel';
import useNotification from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  root: {
    paddingBottom: '8rem',
  },
  sectionDescription: {
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
  sectionWarning: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}));

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

function Settings() {
  const { showAlert } = useNotification();
  const classes = useStyles();
  const { t } = useTranslation();
  const checkLanguage = useFeature('new_profile_language_temp');
  const [downloadFile, setDownloadFile] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [deleteAccountDisabled, setDeleteAccountDisabled] = useState(false);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const tokenInfo = getIdTokenInfo();
        const userId = tokenInfo.sub.replace('auth0|', '');

        const requestResponseUser = await getUserData(userId);
        const userInfo = requestResponseUser.data;

        const requestResponseOrganizations = await getUserOrganizations(userId);
        const userOrganizations = requestResponseOrganizations.data;

        const ownedOrganizations = userOrganizations.filter(
          organization =>
            organization.org_type !== 'user' && organization.organization_role.name === 'owner',
        );

        for (let i = 0; i < ownedOrganizations.length; i += 1) {
          const requestResponseUsersOrg = await getCurrentOrgUsers(ownedOrganizations[i]._id);
          const membersList = requestResponseUsersOrg.data;
          const hasOtherOwner = membersList[0]?.members_list.some(
            member => member.name !== userInfo.name && member.org_role === 'owner',
          );
          if (!hasOtherOwner) {
            setDeleteAccountDisabled(true);
            break;
          }
        }

        userInfo.picture = getIdTokenInfo().picture;
        setUserData(userInfo);

        setLoadingPage(false);
      } catch (err) {
        setLoadingPage(false);
        showAlert('error', t('profile.dataError'));
      }
    };
    loadUser();
  }, [t, showAlert]);

  function closeDeleteAccountModal() {
    setOpenDeleteModal(false);
  }

  async function handleDownload() {
    mixpanel.track('Download User Data');
    const data = `text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(userData))}`;
    setDownloadFile(data);
  }

  return (
    <>
      <Main maxWidth={false} className={classes.root}>
        <Typography className={classes.title} variant="h2">
          {t('settings.title')}
        </Typography>
        {checkLanguage && (
          <Box mt={5}>
            <Typography className={classes.sectionTitle} variant="h4">
              {t('settings.language.title')}
            </Typography>
            <Typography className={classes.sectionDescription}>
              {t('settings.language.description')}
            </Typography>
            <LanguageForm userInfo={userData} showToastAlert={showAlert} />
          </Box>
        )}
        <Box mt={5}>
          <Typography className={classes.sectionTitle} variant="h4">
            {t('settings.downloadData.title')}
          </Typography>
          <Typography className={classes.sectionDescription}>
            {t('settings.downloadData.description')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownload}
            href={`data: ${downloadFile}`}
            download="data.json"
          >
            {t('settings.downloadData.button')}
          </Button>
        </Box>
        <Box mt={5}>
          <Typography className={classes.sectionTitle} variant="h4">
            {t('settings.deleteAccount.title')}
          </Typography>
          <Typography className={classes.sectionDescription}>
            {t('settings.deleteAccount.description')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={deleteAccountDisabled}
            className={classes.deleteButton}
            onClick={() => setOpenDeleteModal(true)}
          >
            {t('settings.deleteAccount.button')}
          </Button>
          {deleteAccountDisabled && (
            <Box mt={2}>
              <Typography className={classes.sectionWarning}>
                <WarningIcon />
                {t('settings.deleteAccount.warning')}
              </Typography>
            </Box>
          )}
          <DeleteAccountModal
            onClose={closeDeleteAccountModal}
            open={openDeleteModal}
            showAlert={showAlert}
            user={userData}
          />
        </Box>
      </Main>
      <MaskedLoader loading={loadingPage} />
    </>
  );
}
export default Settings;
