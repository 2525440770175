import auth0 from 'auth0-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import { cleanFeatures } from '@eyblockchain/subscription-sdk/browser';
import { addCookieSecure } from '../utils/helper';

async function setSession(authResult) {
  Cookies.set(`${window.platformConfig.cookiePrefix}access_token`, authResult.accessToken, {
    domain: window.platformConfig.cookieDomain,
    expires: 1,
    secure: addCookieSecure(),
  });
  Cookies.set(`${window.platformConfig.cookiePrefix}id_token`, authResult.idToken, {
    domain: window.platformConfig.cookieDomain,
    expires: 1,
    secure: addCookieSecure(),
  });
}

export default class Auth0EmbeddedLogin {
  constructor() {
    this.auth0Client = new auth0.WebAuth({
      domain: window.config.auth0Domain,
      clientID: window.config.auth0Client,
      audience: window.config.auth0Audience,
      responseType: 'token id_token',
    });
    this.signIn = this.signIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.forgotPass = this.forgotPass.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  signIn(email, password) {
    const redirectUri = Cookies.get(`${window.platformConfig.cookiePrefix}redirect-uri`);
    return new Promise((resolve, reject) => {
      this.auth0Client.client.login(
        {
          realm: window.config.auth0Realm,
          username: email,
          password,
          scope: 'openid email profile',
          redirectUri: decodeURIComponent(redirectUri),
        },
        (err, authResult) => {
          if (err) {
            return reject(err);
          }
          setSession(authResult);
          return resolve();
        },
      );
    });
  }

  signUp(values) {
    return new Promise((resolve, reject) => {
      this.auth0Client.signup(
        {
          connection: window.config.auth0Realm,
          name: values.full_name,
          email: values.email_address,
          password: values.password,
        },
        err => {
          if (err) {
            return reject(err);
          }
          return resolve();
        },
      );
    });
  }

  async forgotPass(email) {
    const token = Cookies.get(`${window.platformConfig.cookiePrefix}access_token`);

    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const url = `${window.platformConfig.authApiUrl}/users/status/${email}`;

    const emailVerified = await axios.get(url, options);

    if (emailVerified.data.status) {
      return new Promise((resolve, reject) => {
        this.auth0Client.changePassword(
          {
            connection: window.config.auth0Realm,
            email,
          },
          (err, resp) => {
            if (err) {
              return reject(err);
            }
            return resolve(resp);
          },
        );
      });
    }
    return new Error('User not verified');
  }

  signOut(returnUrl) {
    const signOutReturnTo = returnUrl || `${window.config.headerLogoUrl}/login`;
    Cookies.remove(`${window.platformConfig.cookiePrefix}redirect-uri`, {
      domain: window.platformConfig.cookieDomain,
    });

    Cookies.remove(`${window.platformConfig.cookiePrefix}access_token`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}id_token`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}address`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}publicKey`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}blockchainType`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}encryptionType`, {
      domain: window.platformConfig.cookieDomain,
    });

    Cookies.remove(`${window.platformConfig.cookiePrefix}active_organization`, {
      domain: window.platformConfig.cookieDomain,
    });

    cleanFeatures(); // Remove subscriptions cookie

    return new Promise((resolve, reject) => {
      this.auth0Client.logout(
        {
          returnTo: decodeURIComponent(signOutReturnTo),
          clientID: window.config.auth0Client,
        },
        err => {
          if (err) {
            return reject(err);
          }
          return resolve();
        },
      );
    });
  }
}
