import Cookies from 'js-cookie';
import { cleanFeatures } from '@eyblockchain/subscription-sdk/browser';
import AuthingSSO from '@authing/sso';

const basicScope = ['openid', 'profile', 'email', 'phone'];

const configuredScope = [
  'access:subscriptions',
  'read:users',
  'update:users',
  'delete:users',
  'assign:user_roles',
  'read:user_roles',
];

export default class {
  constructor(redirectUrl) {
    this.authingSSOClient = new AuthingSSO({
      appId: window.platformConfig.authingAppID,
      appType: 'oidc',
      appDomain: window.platformConfig.appDomain,
      scope: [...basicScope, ...configuredScope].join(' '),
      redirectUrl,
    });
  }

  async signOut(returnUrl) {
    Cookies.remove(`${window.platformConfig.cookiePrefix}redirect-uri`, {
      domain: window.platformConfig.cookieDomain,
    });

    Cookies.remove(`${window.platformConfig.cookiePrefix}access_token`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}id_token`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}address`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}publicKey`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}blockchainType`, {
      domain: window.platformConfig.cookieDomain,
    });
    Cookies.remove(`${window.platformConfig.cookiePrefix}encryptionType`, {
      domain: window.platformConfig.cookieDomain,
    });

    Cookies.remove(`${window.platformConfig.cookiePrefix}active_organization`, {
      domain: window.platformConfig.cookieDomain,
    });

    cleanFeatures(); // Remove subscriptions cookie

    await this.authingSSOClient.logout();
    window.location.href = returnUrl;
  }
}
