import mixpanel from 'mixpanel-browser';
import { getIdTokenInfo } from './fetch';

/**
 * This function tracks an event on mixpanel
 * @param {String} eventName - Registered event name in mixpanel.
 * @param {Object} metadata - Object containing event specific data
 */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
function track(event, meta) {
  try {
    if (window.platformConfig.mixpanelId) {
      // mixpanel.track(event, meta);
    }
  } catch (error) {
    // Handle later
  }
}

/**
 * Initializes mixpanel package.
 * @param {String} mixpanelId - Mixpanel ID.
 */
function init(mixpanelId) {
  if (window.platformConfig.mixpanelId) {
    mixpanel.init(mixpanelId);
  }
}

/**
 * Returns user ID from cookies.
 * @returns {String}
 */
function getUserId() {
  const tokenInfo = getIdTokenInfo();
  if (!tokenInfo) return null; // No token available
  return tokenInfo.sub.replace('auth0|', '');
}

function checkInternalUser() {
  const tokenInfo = getIdTokenInfo();
  if (tokenInfo && /@([a-zA-Z]*\.)*ey.com\s*$/.test(tokenInfo.email)) {
    return true;
  }
  return false;
}

/**
 * Function extracts user id from cookie token
 * and sets it as distinct_id in mixpanel.
 */
function setUserIdentity() {
  try {
    const userId = getUserId();
    const internal = checkInternalUser();
    if (userId && window.platformConfig.mixpanelId) {
      mixpanel.identify(userId);
      mixpanel.register({ internal });
    }
  } catch (error) {
    // Handle later
  }
}

function setOrganizationUserData({ _id, org_type: orgType }) {
  try {
    if (window.platformConfig.mixpanelId)
      mixpanel.register({
        'Organization ID': _id,
        'Organization Type': orgType,
      });
  } catch (error) {
    // handle later
  }
}

export default {
  track,
  init,
  setUserIdentity,
  setOrganizationUserData,
};
