import React, { useContext, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@eyblockchain/ey-ui/core/Modal';
import { WalletContext } from '@eyblockchain/ey-ui/core/BecWalletMenu';

const useStyles = makeStyles(theme => ({
  keyInfo: {
    marginLeft: theme.spacing(2),
  },
  chxLabel: {
    cursor: 'pointer',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
  },
}));

function DeleteKeyModal({ open, onClose, showAlert, keyPair }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [checkboxConfirm, setCheckboxConfirm] = useState(false);
  const { t } = useTranslation();
  const { loadWallet, deleteKey } = useContext(WalletContext);

  const onModalClose = () => {
    setCheckboxConfirm(false);
    onClose();
  };

  const clickDeleteButton = async () => {
    let response;
    try {
      response = await deleteKey(keyPair.address);
      showAlert('success', t('wallet.deleteModal.success'));
    } catch (err) {
      showAlert('error', t('wallet.deleteModal.error'));
    } finally {
      setCheckboxConfirm(false);
    }
    if (response?.status === 204) {
      onModalClose();
      await loadWallet();
    }
  };
  return (
    <Modal
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      scroll="body"
      title={t('wallet.deleteModal.title')}
      showCloseIcon
      open={open}
      onClose={onModalClose}
      splitActions={false}
      rightActionOptions={{
        name: t('wallet.deleteModal.title'),
        color: 'primary',
        variant: 'contained',
        startIcon: null,
        disabled: !checkboxConfirm,
        className: classes.deleteButton,
        onClick: () => {
          clickDeleteButton();
        },
      }}
      leftActionOptions={{
        name: t('wallet.action.cancel'),
        color: 'primary',
        variant: 'outlined',
        startIcon: null,
        onClick: onModalClose,
      }}
    >
      <Typography className={classes.sectionText}>{t('wallet.deleteModal.instruction')}</Typography>

      <Box mt={3}>
        <Grid container>
          <Grid item xs={6} className={classes.keyInfo}>
            <Typography component="div">
              <Box fontWeight="fontWeightMedium" fontSize="h6.fontSize">
                {t('wallet.key.name')}
              </Box>
              <Box>{keyPair.name}</Box>
              <Box fontWeight="fontWeightMedium" fontSize="h6.fontSize" mt={2}>
                {t('wallet.key.address')}
              </Box>
              <Box>{keyPair.address}</Box>
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="row" mt={3}>
        <Checkbox
          id="confirmation"
          color="primary"
          onChange={() => setCheckboxConfirm(!checkboxConfirm)}
        />
        <Box alignSelf="center">
          <Typography
            className={classes.chxLabel}
            variant="body2"
            component="label"
            htmlFor="confirmation"
          >
            <Trans i18nKey="wallet.deleteModal.warning">
              <strong>[bold]</strong>
            </Trans>
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

DeleteKeyModal.propTypes = {
  /**
   * Function to close modal .
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Function to toggle modal visibility .
   */
  open: PropTypes.bool.isRequired,
  /**
   * Key data
   */
  keyPair: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,

  /**
   * Function to show toast alerts
   */
  showAlert: PropTypes.func.isRequired,
};

export default DeleteKeyModal;
