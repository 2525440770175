import React from 'react';
import PropTypes from 'prop-types';
import Main from '@eyblockchain/ey-ui/core/Main';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { useTranslation, Trans } from 'react-i18next';
import LoginForm from '../components/LoginForm';
import { privacyUrl, termsAndConditionsUrl, mixpanelEvents } from '../utils/constants';
import mixpanel from '../utils/mixpanel';

const useStyles = makeStyles(() => ({
  termsAndPrivacy: {
    color: '#155CB4',
    fontWeight: 'bold',
  },
  boldLink: {
    fontWeight: 'bold',
  },
}));

const Login = props => {
  const { history } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  function handleSignUp(e) {
    e.preventDefault();
    history.push('/register');
  }

  function trackViewLegalDocumentation(label) {
    mixpanel.track(mixpanelEvents.viewLegalDocumentation, { documentName: label });
  }

  return (
    <Main maxWidth="sm">
      <Typography variant="h3">{t('login.title')}</Typography>
      <LoginForm onSignUp={handleSignUp} history={history} />
      <Box mt={4}>
        <Typography component="span" variant="body2">
          <Trans i18nKey="login.termsAndPrivacyNotice">
            <Link
              href={termsAndConditionsUrl}
              target="_blank"
              underline="always"
              className={classes.termsAndPrivacy}
              onClick={() => {
                trackViewLegalDocumentation('terms');
              }}
            >
              [link]
            </Link>
            and
            <Link
              href={privacyUrl}
              target="_blank"
              underline="always"
              className={classes.termsAndPrivacy}
              onClick={() => {
                trackViewLegalDocumentation('privacy');
              }}
            >
              [link]
            </Link>
          </Trans>
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography component="span">
          <Trans i18nKey="login.getStartedLink">
            <Link href="/register" underline="always" className={classes.boldLink}>
              [link]
            </Link>
          </Trans>
        </Typography>
      </Box>
    </Main>
  );
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Login;
