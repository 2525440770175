import React from 'react';
import PropTypes from 'prop-types';
import Main from '@eyblockchain/ey-ui/core/Main';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    fontSize: '1rem',
  },
}));

const ForgotPassword = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = props;

  return (
    <Main maxWidth="sm">
      <Typography variant="h3" gutterBottom>
        {t('forgot-password.title')}
      </Typography>
      <Typography>{t('forgot-password.instructions')}</Typography>
      <ForgotPasswordForm history={history} />
      <Typography component="div">
        <Box mt={4} mb={5} component="p">
          {t('forgot-password.goToLogInMessage')}
          <Link underline="always" href="/" className={classes.link}>
            {t('forgot-password.goToLogInButton')}
          </Link>
        </Box>
      </Typography>
    </Main>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default ForgotPassword;
