import { ethers } from 'ethers';

export default privKey => {
  try {
    const privKeyWithPrefix = privKey.slice(0, 2) === '0x' ? privKey : `0x${privKey}`;
    const address = ethers.utils.computeAddress(privKeyWithPrefix);
    return address;
  } catch {
    return '';
  }
};
