export const PASSWORD_REGEX = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[@#%&.,]))|((?=.*[a-z])(?=.*[0-9])(?=.*[@#%&.,]))|((?=.*[A-Z])(?=.*[0-9])(?=.*[@#%&.,])))',
);

export const pwRequirements =
  'Your password must be at least 10 characters long and contain at least three characters from the following categories: lower case character (a - z), upper case character (A - Z), number (0 - 9) and non-alphanumeric character (e.g. #@%&.,).';

export const redBoxCountries = [
  'AM', // Armenia
  'AZ', // Azerbaijan
  'BY', // Belarus
  'KZ', // Kazakhstan
  'KG', // Kyrgyzstan
  'MD', // Republic of Moldova
  'RU', // Russia
  'TJ', // Tajikistan
  'UZ', // Uzbekistan
  'FR', // France
  'MN', // Monaco,
  'LU', // Luxembourg
  'DZ', // Algeria - The Maghreb
  'LY', // Libya - The Maghreb
  'MR', // Mauritania - The Maghreb
  'MA', // Morocco - The Maghreb
  'TN', // Tunisia - The Maghreb
  'EH', // Western Sahara - The Maghreb
  'CN', // China
  'MO', // Macao
  'HK', // Hong Kong
];

export const mixpanelEvents = {
  viewLegalDocumentation: 'View Legal Documentation',
};

export const termsAndConditionsUrl = `${window.platformConfig.becUrl}/static/terms.pdf`;

export const privacyUrl = `${window.platformConfig.becUrl}/static/privacy.pdf`;

export const TESTNET = 'ropsten';
