import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Formik, Form, Field, useFormikContext } from 'formik';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import * as Yup from 'yup';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { updateUserInfo } from '../utils/fetch';

const useStyles = makeStyles(() => ({
  avatar: {
    height: '64px',
    width: '64px',
    borderRadius: '50%',
  },
  tooltip: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    minWidth: '54px',
  },
}));

// eslint-disable-next-line func-names
Yup.addMethod(Yup.string, 'userName', function(errorMessage) {
  // eslint-disable-next-line func-names
  return this.test(`user-name-regexp`, errorMessage, function(value) {
    const { path, createError } = this;
    const nameRegExp = new RegExp('[~`!@#$%^&()_={}\\[\\]\\:;,\\.\\/<>\\\\*\\-+\\?]');
    return !nameRegExp.test(value) || createError({ path, message: errorMessage });
  });
});

const FormikEffect = ({ bindFormikProps, reviewFormState }) => {
  const context = useFormikContext();
  useEffect(() => {
    bindFormikProps(context);
    reviewFormState(context);
  }, [context, bindFormikProps, reviewFormState]);

  return null;
};

function ProfileForm({ loading, formData, bindFormikProps, showAlert, onDataUpdated }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [originalEmail, setOriginalEmail] = useState('');
  const [emailTooltip, setEmailTooltip] = useState(false);

  async function submitProfileForm(values, { resetForm }) {
    let message;
    const emailChange = originalEmail !== values.email;
    const pendingEmailChange = values.email !== originalEmail ? values.email : undefined;

    const body = { ...values, pendingEmailChange };
    const { id } = formData;

    try {
      const response = await updateUserInfo(id, body);

      if (response.status === 200) {
        if (values.name !== formData.name && emailChange) {
          message = t('profile.actionMessages.updatedAllData');
          setEmailTooltip(true);
        } else if (emailChange) {
          message = t('profile.actionMessages.updatedEmail');
          setEmailTooltip(true);
        } else message = t('profile.actionMessages.updatedName');

        setOriginalEmail(values.email);
        showAlert('success', message);

        resetForm(values);

        onDataUpdated(values);
      } else {
        throw Error('Request fail');
      }
    } catch (err) {
      resetForm();

      let errorMessage = t('profile.actionMessages.errorInRequest');

      if (
        err.response &&
        err.response.status === 409 &&
        err.response.data.message
          .toLocaleLowerCase()
          .includes('user with this email already exists')
      ) {
        errorMessage = t('profile.actionMessages.errorAccountExist');
      }

      showAlert('error', errorMessage);
    }
  }

  function reviewFormState(formikProps) {
    if (!loading && originalEmail === '' && Object.keys(formikProps.initialValues).length > 0) {
      setOriginalEmail(formikProps.initialValues.email);
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .userName(t('profile.validations.format.name'))
      .required(t('profile.validations.required.fullname')),
    email: Yup.string()
      .email(t('profile.validations.format.email'))
      .required(t('profile.validations.required.email')),
  });

  const profileImage = formData.picture ? (
    <img className={classes.avatar} src={formData.picture} alt="avatar" />
  ) : null;

  return (
    <div>
      <Box py={2}>{loading ? <CircularProgress color="primary" /> : profileImage}</Box>

      <Formik
        initialValues={{
          name: formData.name || '',
          email: formData.email || '',
        }}
        validationSchema={validationSchema}
        onSubmit={submitProfileForm}
        enableReinitialize
      >
        {formikProps => {
          return (
            <Form noValidate autoComplete="off">
              <FormikEffect bindFormikProps={bindFormikProps} reviewFormState={reviewFormState} />
              <Grid item md={4}>
                <FormErrors form={formikProps} />
              </Grid>
              <Grid item md={4}>
                <Field
                  name="name"
                  value={formikProps.values.name}
                  label={t('profile.labels.name')}
                  component={TextField}
                  disabled={loading || Object.keys(formData).length === 0}
                />
              </Grid>
              <Grid container direction="row" wrap="nowrap" alignContent="flex-start">
                <Grid item md={4}>
                  <Field
                    name="email"
                    value={formikProps.values.email}
                    label={t('profile.labels.email')}
                    component={TextField}
                    disabled={loading || Object.keys(formData).length === 0}
                  />
                </Grid>
                {emailTooltip && (
                  <Tooltip
                    title={t('profile.actionMessages.emailTooltip')}
                    className={classes.tooltip}
                    placement="right"
                  >
                    <Button>
                      <WarningIcon />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

ProfileForm.propTypes = {
  /**
   * Boolean value to indicate if the parent finishes loading the user data.
   */
  loading: PropTypes.bool.isRequired,

  /**
   * Form data .
   */
  formData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,

  /**
   * Function to bind the formik functions on the parent component.
   */
  bindFormikProps: PropTypes.func.isRequired,
  /**
   * Function to show toast alerts
   */
  showAlert: PropTypes.func.isRequired,
  /**
   * Function to update information on parent.
   */
  onDataUpdated: PropTypes.func.isRequired,
};

export default ProfileForm;
