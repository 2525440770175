import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    debug: false,
    load: 'languageOnly',
    ns: ['translation', 'my-account', 'universal-login'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    detection: {
      lookupCookie: 'next-i18next',
      cookieDomain: window.platformConfig.cookieDomain,
      cookieOptions: { path: '/', sameSite: 'lax' },
      order: [
        'cookie',
        'querystring',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
  });

export default i18n;
