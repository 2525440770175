import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
  },
  iconColor: ({ type }) => {
    if (!type) return null;

    const colors = new Map([
      ['warning', theme.palette.warning.lightest],
      ['info', '#155CB4'],
    ]);

    return {
      color: colors.get(type) || theme.palette[type].main,
    };
  },
}));

const MessageBox = props => {
  const { className: classNameProp, children, icon, type, ...other } = props;

  const classes = useStyles({ type });
  const className = clsx(classes.root, classNameProp);

  const iconProp = icon !== null ? icon : <ErrorIcon className={classes.iconColor} />;

  return (
    <Box
      display="flex"
      flexDirection="row"
      p={2}
      my={2}
      bgcolor="background.paper"
      className={className}
      {...other}
    >
      <Box pr={2}>{iconProp}</Box>
      {children}
    </Box>
  );
};

MessageBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.element,
  type: PropTypes.string,
};

MessageBox.defaultProps = {
  className: null,
  children: null,
  icon: null,
  type: 'info',
};

export default MessageBox;
