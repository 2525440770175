import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@eyblockchain/ey-ui/core/TextField';
import FormErrors from '@eyblockchain/ey-ui/core/FormErrors';
import Cookies from 'js-cookie';
import { refreshSubscriptions } from '@eyblockchain/subscription-sdk/browser';
import Snackbar from '@eyblockchain/ey-ui/core/Snackbar';
import MessageBox from './MessageBox';
import mixpanel from '../utils/mixpanel';
import i18n from '../i18n';
import { getIdTokenInfo } from '../utils/fetch';
import Auth0EmbeddedLogin from '../idaasProviders/Auth0EmbeddedLogin';
import { setCustomCookies, getOrganizationCookies } from '../utils/helper';

const authService = new Auth0EmbeddedLogin();

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(3),
    '&:disabled': {
      border: '0.0625rem solid #C4C4CD',
    },
  },
  label: {
    fontWeight: 'bold',
    padding: theme.spacing(2, 1),
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const LoginForm = ({ history, onSignUp, className: classNameProp, ...other }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false); // toggle password visibilty
  const [loginFail, setLoginFail] = useState(null);
  const [failReason, setFailReason] = useState('login.errors.authorization.notKnown');
  const [loggingIn, setLoggingIn] = useState(false);
  const [toastState, setToastState] = useState({
    open: false,
    variant: null,
    message: '',
    customActions: [],
  });

  const className = clsx(classes.root, classNameProp);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastState({ ...toastState, open: false });
  };

  const showToastAlert = (type, message, customActions = []) => {
    setToastState({ open: true, variant: type, message, customActions });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(location.search.replace('?', '')));
    if (queryParams.origin) {
      if (queryParams.success === 'true') {
        if (queryParams.origin === 'verification') mixpanel.track('Account Verification');
        showToastAlert('success', `${queryParams.origin}Success`);
      } else {
        const params = btoa(location.search.replace('?', ''));
        if (queryParams.origin === 'verification') mixpanel.track('Failed Account Verification');
        history.push(`/confirmation?${params}`);
      }
    }
  }, [location, history]);

  function checkFailedLogin(err) {
    if (err.statusCode === 403 && err.description.toLowerCase() === 'wrong email or password.') {
      setFailReason('login.errors.authorization.accessDenied');
    } else if (err.statusCode === 401 && err.description.toLowerCase() === 'email not verified') {
      setFailReason('login.errors.authorization.notVerified');
    } else if (err.statusCode === 429 && err.code.toLowerCase() === 'too_many_attempts') {
      setFailReason('login.errors.authorization.blocked');
    }
  }

  async function login(values) {
    try {
      await authService.signIn(values.email_address.toLowerCase(), values.password);
      await setCustomCookies();
      await refreshSubscriptions();
      setLoginFail(false);

      const { defaultOrgId, defaultOrgType } = getOrganizationCookies();

      // mixpanel events
      mixpanel.setUserIdentity();
      mixpanel.setOrganizationUserData({ _id: defaultOrgId, org_type: defaultOrgType });
      mixpanel.track('Sign In');

      // change language preferences
      const tokenInfo = getIdTokenInfo();
      if (tokenInfo[`${window.platformConfig.becUrl}/language`])
        changeLanguage(tokenInfo[`${window.platformConfig.becUrl}/language`]);

      // redirect to original url
      const redirectUri = Cookies.get(`${window.platformConfig.cookiePrefix}redirect-uri`);
      Cookies.remove(`${window.platformConfig.cookiePrefix}redirect-uri`);
      window.location =
        redirectUri !== undefined
          ? decodeURIComponent(redirectUri)
          : window.config.defaultRedirectUrl;
    } catch (err) {
      mixpanel.track('Failed Sign In');
      checkFailedLogin(err);
      setLoginFail(true);
      setLoggingIn(false);
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.warn(`Error on login: ${err.message}` ? err.message : '');
    }
  }
  function handleSubmit(values) {
    setLoggingIn(true);
    login(values);
  }

  const handleChange = (field, { setFieldValue, setErrors, errors }) => e => {
    setFieldValue(field, e.target.value);
    if (Object.values(errors).length > 0) setErrors({});
    if (loginFail) setLoginFail(false);
  };

  const validationSchema = Yup.object().shape({
    email_address: Yup.string()
      .email(t('login.errors.format.email'))
      .required(t('login.errors.required.email')),
    password: Yup.string().required(t('login.errors.required.password')),
  });

  return (
    <>
      <Formik
        initialValues={{
          email_address: '',
          password: '',
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formProps => (
          <Form noValidate className={className} {...other} autoComplete="off">
            <FormErrors form={formProps} />

            {loginFail && Object.keys(formProps.errors).length === 0 && (
              <MessageBox type="error">
                <Typography component="span" variant="body2">
                  <Trans i18nKey={failReason}>
                    <strong>[bold]</strong>
                    <Link href="/register" underline="always" className={classes.bold}>
                      [link]
                    </Link>
                  </Trans>
                </Typography>
              </MessageBox>
            )}

            <Grid container alignItems="center">
              <Field
                name="email_address"
                label={t('login.labels.email')}
                value={formProps.values.email_address}
                component={TextField}
                variant="outlined"
                autoComplete="off"
                onChange={handleChange('email_address', formProps)}
                required
              />
              <Field
                name="password"
                label={t('login.labels.password')}
                value={formProps.values.password}
                component={TextField}
                variant="outlined"
                autoComplete="new-password"
                onChange={handleChange('password', formProps)}
                visibility="hidden"
                type={showPassword ? 'text' : 'password'}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityOff
                            color={
                              formProps.dirty &&
                              formProps.errors.password &&
                              formProps.touched.password
                                ? 'error'
                                : 'inherit'
                            }
                          />
                        ) : (
                          <Visibility
                            color={
                              formProps.dirty &&
                              formProps.errors.password &&
                              formProps.touched.password
                                ? 'error'
                                : 'inherit'
                            }
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Typography component="div">
              <Box fontWeight="bold" textAlign="right" mt={2}>
                <Link underline="always" href="/forgot-password">
                  {t('login.forgotPasswordLink')}
                </Link>
              </Box>
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submitButton}
              disabled={loggingIn}
            >
              {t('login.buttons.signin')}
            </Button>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={toastState.open}
        onClose={handleClose}
        message={t(`login.alerts.${toastState.message}`)}
        variant={toastState.variant}
        customActions={toastState.customActions}
      />
    </>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
  onSignUp: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

LoginForm.defaultProps = {
  className: null,
};

export default LoginForm;
