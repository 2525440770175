import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Main from '@eyblockchain/ey-ui/core/Main';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import { WalletContext } from '@eyblockchain/ey-ui/core/BecWalletMenu';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import DeleteKeyModal from '../../components/DeleteKeyModal';
import AddKeyModal from '../../components/AddKeyModal';
import useNotification from '../../hooks/useNotification';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '8rem',
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  keyTitle: {
    marginBottom: theme.spacing(1),
  },
  cardRoot: {
    display: 'flex',
  },
  content: {
    alignItems: 'top',
    flex: '4',
  },
  cover: {
    flex: '1',
    alignSelf: 'center',
  },
  graphic: {
    width: '4.5rem',
    height: '4.5rem',
  },
  cent: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  right: {
    display: 'flex',
    flex: '1',
    marginRight: '1rem',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    flexDirection: 'row',
  },
}));

function Wallet() {
  const { showAlert } = useNotification();
  const { wallets, loadingWallets } = useContext(WalletContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [targetKey, setTargetKey] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  // Option can be hardcoded as they are generally fixed unless new developer setup
  const blockchainType = {
    BCOS: t('wallet.key.nameFisco'),
    ETH: t('wallet.key.nameEth'),
    BSN: t('wallet.key.nameBSN'),
  };
  // Options can be hardcoded as they are static
  const bcosEncryptionType = {
    ECDSA: t('wallet.key.encryptionEcdsa'),
    SM_CRYPTO: t('wallet.key.encryptionSm'),
  };
  const bsnEncryptionType = {
    SM_CRYPTO: t('wallet.key.encryptionBSN'),
  };

  const handleDelete = keyPair => {
    setTargetKey(keyPair);
    setOpenDeleteModal(true);
  };

  const WalletList = () => {
    const haveWallet = wallets && wallets.length > 0;
    if (loadingWallets) {
      return <MaskedLoader loading={loadingWallets} />;
    }

    if (haveWallet) {
      return wallets.map(keyPair => {
        const icon = (
          <CardContent className={classes.cover}>
            <div className={classes.cent}>
              {keyPair.blockchainType === 'BCOS' && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/fisco.svg`}
                  className={classes.graphic}
                  alt="FISCO BCOS"
                />
              )}
              {keyPair.blockchainType === 'ETH' && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/ethereum.svg`}
                  className={classes.graphic}
                  alt="Ethereum"
                />
              )}
              {keyPair.blockchainType === 'BSN' && (
                <img
                  src={`${process.env.PUBLIC_URL}/images/bsn.svg`}
                  className={classes.graphic}
                  alt="BSN"
                />
              )}
            </div>
          </CardContent>
        );

        const content = (
          <CardContent className={classes.content}>
            <Typography variant="h5" className={classes.keyTitle}>
              <b>{keyPair.name}</b>
            </Typography>
            <table>
              <tbody>
                <tr>
                  <td>{t('wallet.key.address')}:</td>
                  <td>{keyPair.address}</td>
                </tr>
                <tr>
                  <td>{t('wallet.key.type')}:</td>
                  <td>{blockchainType[keyPair.blockchainType]}</td>
                </tr>
                {keyPair.blockchainType === 'BCOS' && (
                  <tr>
                    <td>{t('wallet.key.encryption')}:</td>
                    <td>{bcosEncryptionType[keyPair.encryptType]}</td>
                  </tr>
                )}
                {keyPair.blockchainType === 'BSN' && (
                  <tr>
                    <td>{t('wallet.key.encryption')}:</td>
                    <td>{bsnEncryptionType[keyPair.encryptType]}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </CardContent>
        );

        const actions = (
          <CardContent className={classes.cover}>
            <Button variant="contained" color="secondary" onClick={e => handleDelete(keyPair, e)}>
              {t('wallet.action.delete')}
            </Button>
          </CardContent>
        );

        return (
          <Card className={classes.cardRoot} key={keyPair.address}>
            {icon}
            {content}
            {actions}
          </Card>
        );
      });
    }

    return (
      <Card className={classes.cardRoot}>
        <CardContent>{t('wallet.emptyWarning')}</CardContent>
      </Card>
    );
  };

  return (
    <>
      <Main maxWidth={false} className={classes.root}>
        <Box display="flex">
          <Typography className={classes.title} variant="h2">
            {t('wallet.title')}
          </Typography>
          <div className={classes.right}>
            <Button variant="outlined" color="primary" onClick={() => setOpenAddModal(true)}>
              {t('wallet.action.add')}
            </Button>
          </div>
        </Box>
        <WalletList />
      </Main>

      <DeleteKeyModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        showAlert={showAlert}
        keyPair={targetKey}
      />

      <AddKeyModal
        open={openAddModal}
        onClose={() => {
          setOpenAddModal(false);
        }}
        showAlert={showAlert}
      />
    </>
  );
}
export default Wallet;
