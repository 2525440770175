import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BecFramework from '@eyblockchain/ey-ui/core/BecFramework';
import { makeStyles } from '@material-ui/styles';
import SideNav from '@eyblockchain/ey-ui/core/SideNav';
import SubHeader from '@eyblockchain/ey-ui/core/SubHeader';
import { useFeature } from '@eyblockchain/subscription-sdk/browser';
import { EYUILanguageContext, languages } from '@eyblockchain/ey-ui/core/locale';
import Hero from '@eyblockchain/ey-ui/core/Hero';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import i18n from '../i18n';

const heroMain = require('../assets/HeroMain.jpg');

const useStyles = makeStyles(theme => ({
  main: {
    padding: theme.spacing(0),
  },
  heroSection: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: '35vh',
    },
  },
  contentSection: {
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh',
    },
  },
  pageColor: {
    backgroundColor: theme.palette.primary.lightest,
  },
}));

/**
 * Implements the application's specific layout. Should be used as a wrapper (parent) in all pages.
 */
function Layout({ children }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const enableWalletFeature = useFeature('wallet_management_temp');
  const classes = useStyles();
  const accountPage = window.location.pathname.startsWith('/account');
  const myAccountNav = [
    {
      name: t('myAccountNav.profile'),
      key: 'profile',
      url: `${window.location.origin}/account/profile`,
      onClick: () => history.push('/account/profile'),
    },
    {
      name: t('myAccountNav.wallet'),
      key: 'wallet',
      url: `${window.location.origin}/account/wallet`,
      onClick: () => history.push('/account/wallet'),
      subscription: enableWalletFeature,
    },
    {
      name: t('myAccountNav.security'),
      key: 'security',
      url: `${window.location.origin}/account/security`,
      onClick: () => history.push('/account/security'),
    },
    {
      name: t('myAccountNav.settings'),
      key: 'settings',
      url: `${window.location.origin}/account/settings`,
      onClick: () => history.push('/account/settings'),
    },
    {
      name: t('myAccountNav.legal'),
      key: 'legal',
      url: `${window.location.origin}/account/legal`,
      onClick: () => history.push('/account/legal'),
    },
  ].filter(i => i.subscription !== false);

  const getSelected = () => {
    const currentPath = pathname.replace('/account/', '');
    const selectedItem = myAccountNav
      .filter(item => item.key.toLowerCase().includes(currentPath))
      .map(item => item.name);
    return selectedItem[0];
  };

  const AccountContentLayout = (
    <BecFramework
      appName={t('layout.headerAppName')}
      defaultLanguage={i18n.language}
      showWalletMenu={!!enableWalletFeature}
    >
      <SubHeader maxWidth={false} title={t('profile.subheaderTitle')} />
      <SideNav
        variant="permanent"
        selected={getSelected()}
        navigationList={myAccountNav}
        contentClassName={classes.contentSection}
      >
        {children}
      </SideNav>
    </BecFramework>
  );

  const CustomUILayout = (
    <BecFramework
      appName={t('layout.headerAppName')}
      blankHeader
      defaultLanguage={i18n.language}
      showWalletMenu={!!enableWalletFeature}
    >
      <Grid container>
        <Grid item md={4} xs={12}>
          <Hero image={heroMain} className={classes.heroSection}>
            <Box mb={1} mr={1}>
              <Typography variant="h3" gutterBottom>
                {t('layout.hero.title')}
              </Typography>
              <Typography>{t('layout.hero.subtitle')}</Typography>
            </Box>
          </Hero>
        </Grid>
        <Grid item md={8} xs={12} className={classes.contentSection}>
          <Box
            justifyContent="center"
            display="flex"
            alignItems="center"
            className={clsx(classes.contentSection, classes.pageColor)}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </BecFramework>
  );

  return (
    <>
      <EYUILanguageContext.Provider value={languages[i18n.language || 'en']}>
        {accountPage && AccountContentLayout}
        {!accountPage && CustomUILayout}
      </EYUILanguageContext.Provider>
    </>
  );
}

Layout.propTypes = {
  /**
   * Elements to rendered within the layout (the entire page content)
   */
  children: PropTypes.node.isRequired,
};

export default Layout;
