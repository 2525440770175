import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Main from '@eyblockchain/ey-ui/core/Main';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import publicIp from 'public-ip';
import mixpanel from '../utils/mixpanel';
import RegistrationForm from '../components/RegistrationForm';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(8, 0),
  },
  link: {
    color: '#155CB4',
    fontWeight: 'bold',
  },
  logInLink: {
    marginTop: theme.spacing(3),
  },
}));

const Registration = props => {
  const { history } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    mixpanel.track('View Sign Up Page');
  }, []);

  useEffect(() => {
    (async () => {
      if (window.config.disableRegistration) {
        const params = btoa('origin=registrationDisabled');
        history.push(`/confirmation?${params}`);
      }

      try {
        const ipAddress = await publicIp.v4();

        const response = await fetch(
          `${window.platformConfig.authApiUrl}/location/verify/country/${ipAddress}`,
        );
        const data = await response.json();

        if (data.status === 'NOT ALLOWED') {
          const params = btoa('origin=redBoxCountry');
          history.push(`/confirmation?${params}`);
        }
      } catch (ex) {
        history.push('/confirmation');
      }
    })();
  }, [history]);

  return (
    <Main maxWidth="xs" className={classes.root}>
      <Typography variant="h4">{t('registration.title')}</Typography>

      <Box mt={3} mb={2}>
        <Typography variant="h5">{t('registration.instructions')}</Typography>
      </Box>

      <RegistrationForm history={history} />

      <Box mt={3}>
        <Typography component="span" className={classes.logInLink}>
          {t('registration.goToLogInMessage')}
          <Box pl={1} component="span" fontWeight="bold">
            <Link underline="always" href="/">
              {t('registration.goToLogInButton')}
            </Link>
          </Box>
        </Typography>
      </Box>
    </Main>
  );
};

Registration.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Registration;
