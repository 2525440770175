import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { WalletContext } from '@eyblockchain/ey-ui/core/BecWalletMenu';
import { loadWallets, setCurrentKey } from '@eyblockchain/authentication-sdk/browser';
import { useFeature } from '@eyblockchain/subscription-sdk/browser';
import { createUserKey, deleteUserKey } from '../utils/fetch';
import { addCookieSecure } from '../utils/helper';

const { cookiePrefix: prefix, cookieDomain: domain } = window.config;

const BecWalletProvider = ({ children }) => {
  const [defaultWallet, setDefaultWallet] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const walletAccess = useFeature('wallet_management_temp');

  const setCookieCurrentKey = useCallback(({ blockchainType, publicKey, address }) => {
    const attributes = {
      domain,
      expires: 1,
      secure: addCookieSecure(),
    };
    Cookies.set(`${prefix}blockchainType`, blockchainType, attributes);
    Cookies.set(`${prefix}publicKey`, publicKey, attributes);
    Cookies.set(`${prefix}address`, address, attributes);
  }, []);

  const loadWallet = useCallback(async () => {
    setLoadingWallets(true);
    const res = await loadWallets();
    if (res.length > 0) {
      const currentKey = res.find(({ isCurrent }) => isCurrent) || res[0];
      setDefaultWallet(currentKey);
      setCookieCurrentKey(currentKey);
    }
    setWallets(res);
    const addressCookie = Cookies.get(`${prefix}address`);
    if (addressCookie) Cookies.remove(`${prefix}address`, { domain });
    setLoadingWallets(false);
  }, [setCookieCurrentKey]);

  const setCurrentWallet = async key => {
    setLoadingWallets(true);
    try {
      await setCurrentKey(key.address);
    } catch (e) {
      return;
    }
    setDefaultWallet(key);
    setCookieCurrentKey(key);
    setLoadingWallets(false);
  };

  const deleteKey = async address => {
    setLoadingWallets(true);
    try {
      return await deleteUserKey(address);
    } finally {
      setLoadingWallets(false);
    }
  };

  const createKey = async values => {
    setLoadingWallets(true);
    try {
      return await createUserKey(values);
    } finally {
      setLoadingWallets(false);
    }
  };

  useEffect(() => {
    if (walletAccess) {
      loadWallet();
    }
  }, [walletAccess, loadWallet]);

  return (
    <WalletContext.Provider
      value={{
        defaultWallet,
        wallets,
        loadingWallets,
        setCurrentWallet,
        loadWallet,
        deleteKey,
        createKey,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

BecWalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BecWalletProvider;
