import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaskedLoader from '@eyblockchain/ey-ui/core/MaskedLoader';
import useUserInfo from '../hooks/useUserInfo';

const useStyles = makeStyles(theme => ({
  dropdown: {
    width: '100%',
    marginRight: theme.spacing(4),
  },
}));

const languageOptions = [
  {
    id: 'zh',
    name: '中文',
  },
  {
    id: 'en',
    name: 'English',
  },
];

const LanguageForm = ({ showToastAlert }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { updatePreferenceLanguage } = useUserInfo();
  const [loadingChanges, setLoadingChanges] = useState(false);

  const onChangeHandle = async event => {
    setLoadingChanges(true);
    await updatePreferenceLanguage(event.target.value);
    setLoadingChanges(false);
    showToastAlert('info', t('settings.language.warning'));
  };

  return (
    <Grid item xs={10} sm={6} md={4} lg={3}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="language-select-label">{t('settings.language.labels.language')}</InputLabel>
        <Select
          required
          labelId="language-select-label"
          name="language"
          value={i18n.language}
          label={t('settings.language.labels.language')}
          onChange={onChangeHandle}
          className={classes.dropdown}
        >
          {languageOptions.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MaskedLoader loading={loadingChanges} />
    </Grid>
  );
};

LanguageForm.propTypes = {
  /**
   * User data
   */
  userInfo: PropTypes.shape({
    id: PropTypes.string,
    user_metadata: PropTypes.shape(),
  }).isRequired,
  /**
   * Function that trigger toast alert
   */
  showToastAlert: PropTypes.func.isRequired,
};

export default LanguageForm;
