import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useFeature } from '@eyblockchain/subscription-sdk/browser';
import { login } from '@eyblockchain/authentication-sdk/browser';
import Layout from './components/Layout';
import Logout from './components/Logout';
import ForgotPassword from './pages/ForgotPassword';
import Confirmation from './pages/Confirmation';
import Profile from './pages/account/Profile';
import Settings from './pages/account/Settings';
import Legal from './pages/account/Legal';
import Security from './pages/account/Security';
import Wallet from './pages/account/Wallet';
import Login from './pages/Login';
import Registration from './pages/Registration';
import useUserInfo from './hooks/useUserInfo';

const App = ({ history }) => {
  const checkWalletManagementFeature = useFeature('wallet_management_temp');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { getPreferenceLanguage } = useUserInfo();
  const getLanguage = useCallback(getPreferenceLanguage, []);
  useEffect(() => {
    const cookies = login('', true);
    setIsLoggedIn(!!cookies.accessToken);
    if (isLoggedIn) {
      getLanguage();
    }
  }, [isLoggedIn, getLanguage]);
  return (
    <Layout>
      <Switch>
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/confirmation" component={Confirmation} />
        <Route exact path="/logout" component={Logout} />
        {!isLoggedIn && (
          <>
            <Route
              exact
              path="/"
              component={() => {
                if (window.platformConfig?.authProvider === 'authing') {
                  window.location.href = `${window.platformConfig.becUrl}/login`;
                  return null;
                }
                return <Login history={history} />;
              }}
            />
            <Route exact path="/register" component={Registration} />
            <Route
              path="/account/"
              component={() => {
                window.location.href = '/';
              }}
            />
          </>
        )}
        {isLoggedIn && (
          <>
            <Route
              exact
              path="/"
              component={() => {
                window.location.href = `${window.platformConfig.becUrl}/dashboard`;
                return null;
              }}
            />
            <Route
              exact
              path="/register"
              component={() => {
                window.location.href = `${window.platformConfig.becUrl}/dashboard`;
                return null;
              }}
            />
            {checkWalletManagementFeature && (
              <Route exact path="/account/wallet" component={Wallet} />
            )}
            <Route exact path="/account/settings" component={Settings} />
            <Route exact path="/account/legal" component={Legal} />
            <Route exact path="/account/profile" component={Profile} />
            <Route exact path="/account/security" component={Security} />
          </>
        )}
        <Route render={() => <Confirmation notFound />} />
      </Switch>
    </Layout>
  );
};

App.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default App;
