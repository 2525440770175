import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from '@eyblockchain/ey-ui/core/theme';
import { BecOrganizationProvider } from '@eyblockchain/ey-ui/core/BecFramework';
import merge from 'lodash/merge';
import { configure as subscriptionSdkConfigure } from '@eyblockchain/subscription-sdk/browser';
import { configure as authSdkConfigure } from '@eyblockchain/authentication-sdk/browser';
import App from './App';
import Head from './components/Head';
import NotificationProvider from './context/NotificationProvider';
import Notification from './components/Notification';
import BecWalletProvider from './context/becWallet';
import UserInfoProvider from './context/UserInfoProvider';
import './i18n';
import mixpanel from './utils/mixpanel';

const history = createBrowserHistory();

// merge in optional custom theming
const theme = window.config.customTheme
  ? merge(defaultTheme, window.config.customTheme)
  : defaultTheme;

subscriptionSdkConfigure({
  baseUrl: window.platformConfig.becUrl,
  cookiePrefix: window.platformConfig.cookiePrefix,
  cookieDomain: window.platformConfig.cookieDomain,
  node_env: window.platformConfig.node_env,
});

authSdkConfigure({
  cookiePrefix: window.platformConfig.cookiePrefix,
  cookieDomain: window.platformConfig.cookieDomain,
  authDomain: window.location.origin,
  fallbackRedirect: `${window.becUrl}/dashboard`,
  authApiUrl: window.platformConfig.authApiUrl,
  node_env: window.platformConfig.node_env,
});

mixpanel.init(window.platformConfig.mixpanelId);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={createMuiTheme(theme)}>
      <BecOrganizationProvider>
        <BecWalletProvider>
          <CssBaseline />
          <Suspense fallback={<div />}>
            <Head />
            <UserInfoProvider>
              <NotificationProvider>
                <App history={history} />
                <Notification />
              </NotificationProvider>
            </UserInfoProvider>
          </Suspense>
        </BecWalletProvider>
      </BecOrganizationProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
